import {
  UiRow,
  UiButton,
  UiCard,
  UiIcon,
  UiModal,
  UiModalTypes,
  UiSpace,
  UiTypography,
  UiTypographySymbolName,
  useBreakpoint,
  modalWidths,
  UiQRCode,
} from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, useCallback, useMemo, useState } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import {
  getProfileContactsStorage,
  getProfileStructureInfoStorage,
  profileFullInfoStorage,
} from '@vkph/common/store/profile';
import { getFullNameWithoutPatronymic } from '@vkph/common/utils';
import { usePaddingStyle, useSpace } from '@vkph/ui/hooks';
import { useTheme } from '@vkph/ui/providers/theme';
import QRSvg from '@vkph/ui/svg/qr.svg';
import { getModalStepsForSingleTitle } from '@vkph/ui/utils';
import { getVcardProfileContacts } from '~profile/helpers/get-vcard-profile-contacts';

type QRCardProps = {
  userId: string;
};

export const EmployeeContactsQRCard: FC<QRCardProps> = (props) => {
  const { userId } = props;
  const [{ variables: themeVariables }] = useTheme();
  const [isQrModalOpen, setIsQrModalOpen] = useState(false);
  const profileContactsStorage = useMemo(() => getProfileContactsStorage({ userId }), [userId]);
  const { data: profileFullInfo } = useStore(profileFullInfoStorage.storage.store);
  const { data: contacts } = useAbstractStorage(profileContactsStorage.storage, {
    autoFetchAndRefetch: Boolean(userId),
  });

  const { lg: isLayoutLarge } = useBreakpoint();
  const { spaceXL, spaceM } = useSpace();
  const footerPadding = usePaddingStyle(isLayoutLarge ? [spaceXL, spaceXL, 0] : [spaceM, spaceM, 0]);
  const modalContetnPadding = usePaddingStyle([isLayoutLarge ? spaceXL : spaceM]);

  const profileStructureInfoStorage = useMemo(getProfileStructureInfoStorage, []);
  const { data: profileStructureInfoData } = useAbstractStorage(profileStructureInfoStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { id: userId },
    cancelPendingRequestOnUnmount: true,
  });

  const QRCodeValue = useMemo(() => {
    if (profileFullInfo && profileStructureInfoData && contacts) {
      return getVcardProfileContacts(profileFullInfo, profileStructureInfoData, contacts);
    }

    return '';
  }, [profileFullInfo, profileStructureInfoData, contacts]);
  const modalTitle = useMemo(
    () =>
      profileFullInfo?.main
        ? `${getFullNameWithoutPatronymic(profileFullInfo?.main?.fullName)} — QR-визитка`
        : undefined,
    [profileFullInfo],
  );

  const toggleQrModalOpen = useCallback(() => setIsQrModalOpen((value) => !value), []);

  return (
    <>
      <UiButton.Decorator onClick={toggleQrModalOpen}>
        <UiCard.Footer hasTopBorder emptyPadding style={{ ...footerPadding, marginTop: spaceM }}>
          <UiSpace direction="vertical">
            <UiIcon.Label component={QRSvg}>
              <UiTypography.Title level={3} style={{ margin: 0, color: themeVariables.colorTextSecondary }}>
                QR-визитка
              </UiTypography.Title>
            </UiIcon.Label>
            <UiTypography.Text type="secondary">
              <UiTypography.Link>Просканируйте</UiTypography.Link>
              <UiTypography.Symbol name={UiTypographySymbolName.NBSP} />
              помощью камеры смартфона, чтобы добавить в контакты
            </UiTypography.Text>
          </UiSpace>
        </UiCard.Footer>
      </UiButton.Decorator>

      <UiModal isOpen={isQrModalOpen} type={UiModalTypes.Auto} onClose={toggleQrModalOpen}>
        <UiModal.Header hasBottomBorder>
          <UiModal.Header.Title
            size={isLayoutLarge ? 'middle' : 'small'}
            steps={getModalStepsForSingleTitle(modalTitle)}
          />
        </UiModal.Header>
        <UiModal.Content style={{ ...modalContetnPadding }}>
          <UiRow justify="center">
            <UiQRCode
              errorLevel="L"
              value={QRCodeValue}
              style={{ width: '100%', height: 'auto', minWidth: modalWidths[UiModalTypes.Small] }}
              includeMargin={false}
            />
          </UiRow>
        </UiModal.Content>
      </UiModal>
    </>
  );
};
