import '@vkph/ui/index.scss';
import 'react-app-polyfill/stable';

import { Registry, useRegistry, withRegistry } from '@bem-react/di';
import React, { FC, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { CurrentProfileProvider } from '@vkph/common/providers/current-profile';
import { SettingsConfigService } from '@vkph/common/providers/settings-config';
import { UserIdModel } from '@vkph/common/types/models';
import { SpaceProvider } from '@vkph/ui/providers/space';

import { ThemeService } from './services/theme-service';

const App: FC = () => {
  const { Router, AuthService } = useRegistry('app');
  const [userId, setUserId] = useState<UserIdModel>();

  return (
    <SettingsConfigService>
      <BrowserRouter>
        <AuthService setUserId={setUserId}>
          <CurrentProfileProvider userId={userId}>
            <ThemeService>
              <SpaceProvider>
                <Router />
              </SpaceProvider>
            </ThemeService>
          </CurrentProfileProvider>
        </AuthService>
      </BrowserRouter>
    </SettingsConfigService>
  );
};

export const runApp = (registries: Registry[]) => {
  const AppWithRegistry = withRegistry(...registries)(App);

  const container = document.getElementById('root');

  if (!container) {
    return;
  }

  const root = createRoot(container);

  root.render(<AppWithRegistry />);
};
