import { UiSpinner, useBreakpoint } from '@vkph/ui';
import React, { FC, Suspense, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getProfileStructureInfoStorage, profileFullInfoStorage } from '@vkph/common/store/profile';
import { UserIdModel } from '@vkph/common/types/models';
import { GamificationUserThanksSummaryStorage } from '~profile/store/gamification';
import { getProfileSubdivisionsHeadStorage } from '~profile/store/profile';

const ProfileCardDesktop = React.lazy(() => import('./desktop/ProfileCardDesktopModule'));
const ProfileCardMobile = React.lazy(() => import('./mobile/ProfileCardMobileModule'));

export enum SubscribedEvent {
  Add = 'add',
  Remove = 'remove',
}

type Props = {
  userThanksStorage: GamificationUserThanksSummaryStorage;
};

export const Profile: FC<Props> = (props) => {
  const { userThanksStorage } = props;
  const { id = '' } = useParams<{ id: UserIdModel }>();
  const { lg: isLayoutLarge } = useBreakpoint();

  const profileSubdivisionsHeadStorage = useMemo(getProfileSubdivisionsHeadStorage, []);
  const profileStructureInfoStorage = useMemo(getProfileStructureInfoStorage, []);

  const { data: profileFullInfoData, loading: isProfileFullInfoLoading } = useAbstractStorage(
    profileFullInfoStorage.storage,
    {
      autoFetchAndRefetch: true,
      autoFetchParams: { userId: id },
    },
  );

  const { loading: isProfileSubdivisionsHeadLoading } = useAbstractStorage(
    profileSubdivisionsHeadStorage.storage,
    {
      autoFetchAndRefetch: true,
      autoFetchParams: { userId: id },
      cancelPendingRequestOnUnmount: true,
    },
  );

  const { loading: isProfileStructureInfoLoading } = useAbstractStorage(profileStructureInfoStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { id },
    cancelPendingRequestOnUnmount: true,
  });

  const profileCardProps = {
    userId: id,
    isLoading: isProfileSubdivisionsHeadLoading || isProfileStructureInfoLoading || isProfileFullInfoLoading,
    isProfileFullInfoLoading,
    profileFullInfoData,
    profileStructureInfoStorage,
    profileSubdivisionsHeadStorage,
  };

  return (
    <Suspense fallback={<UiSpinner spinning />}>
      {isLayoutLarge && <ProfileCardDesktop userThanksStorage={userThanksStorage} {...profileCardProps} />}
      {!isLayoutLarge && <ProfileCardMobile {...profileCardProps} />}
    </Suspense>
  );
};
