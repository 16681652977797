import { RouterLink } from '@vkph/components';
import { UiTypography } from '@vkph/ui';
import React, { FC } from 'react';

import { useCurrentProfile } from '@vkph/common/providers/current-profile';
import { GamificationAddedThank } from '@vkph/common/types/models';
import { getFullNameWithoutPatronymic, getRoutePath, RouteNames } from '@vkph/common/utils';

type Props = {
  thank: GamificationAddedThank;
};

export const ThanksHeader: FC<Props> = (props) => {
  const { thank } = props;
  const profile = useCurrentProfile();
  const isReceivedThank = profile.id === thank.toUser.id;
  const user = isReceivedThank ? thank?.fromUser : thank?.toUser;

  const profileName = user?.fullName ? getFullNameWithoutPatronymic(user?.fullName) : '';

  return (
    <UiTypography.Text style={{ marginBottom: 20 }}>
      {isReceivedThank && (
        <UiTypography.Text strong style={{ marginRight: 5 }}>
          от
        </UiTypography.Text>
      )}
      <RouterLink to={getRoutePath(RouteNames.Profile, { id: user.id })}>{profileName}</RouterLink>
    </UiTypography.Text>
  );
};
