import { Registry } from '@bem-react/di';

// TODO проверить работоспособность профиля
// import { ProfilePage } from '../../components/layouts/mail/profile/PageProfile';

import { ProfilePage } from '~profile/components/ProfilePage@default';

import { PageNewsDefault } from '../../components/layouts/mail/news/PageNews@default';
import { PageSearchDefault } from '../../components/layouts/mail/search/PageSearch@default';
import { PageStructureDefault } from '../../components/layouts/mail/structure/PageStructure@default';
import { AuthServiceOIDC } from '../../components/routes/auth/AuthService@oidc';
import { Router } from '../../components/routes/router@default/Router';
import { PagesFrontendRegistry } from '../../typings';

export const pagesFrontendDefaultRegistry = new Registry({ id: 'app' });

pagesFrontendDefaultRegistry.set<PagesFrontendRegistry['ProfilePage']>('ProfilePage', ProfilePage);
pagesFrontendDefaultRegistry.set<PagesFrontendRegistry['PageNews']>('PageNews', PageNewsDefault);
pagesFrontendDefaultRegistry.set<PagesFrontendRegistry['PageSearch']>('PageSearch', PageSearchDefault);
pagesFrontendDefaultRegistry.set<PagesFrontendRegistry['Router']>('Router', Router);
pagesFrontendDefaultRegistry.set<PagesFrontendRegistry['AuthService']>('AuthService', AuthServiceOIDC);
pagesFrontendDefaultRegistry.set<PagesFrontendRegistry['PageStructure']>(
  'PageStructure',
  PageStructureDefault,
);
