"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.VKPHWidgetSDK = void 0;
var _1 = require(".");
var emitter_1 = require("./emitter");
var VKPHWidgetSDK = /** @class */ (function (_super) {
    __extends(VKPHWidgetSDK, _super);
    function VKPHWidgetSDK() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /** Возвращает авторизационные данные для виджета */
    VKPHWidgetSDK.prototype.getAuthToken = function () {
        return (0, _1.sendMessageToDispatcherAsync)({ messageType: 'authToken' });
    };
    /** Возвращает информацию о текущем пользователе */
    VKPHWidgetSDK.prototype.getCurrentUser = function () {
        return (0, _1.sendMessageToDispatcherAsync)({ messageType: 'currentUser' });
    };
    /** Возвращает объект с информацией о цветах текущей темы VK PeopleHub */
    VKPHWidgetSDK.prototype.getColorScheme = function () {
        return (0, _1.sendMessageToDispatcherAsync)({ messageType: 'colorScheme' });
    };
    /** Возвращает uuid данного экземпляра виджета */
    VKPHWidgetSDK.prototype.getUuid = function () {
        return window.name;
    };
    return VKPHWidgetSDK;
}(emitter_1.VKPHEmitter));
exports.VKPHWidgetSDK = VKPHWidgetSDK;
