import { DynamicRenderComponent, PageOverlay } from '@vkph/modules';
import { UiEmpty } from '@vkph/ui';
import React, { FC } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { useQueryParams } from '@vkph/common/hooks';
import { LongreadTypes } from '@vkph/common/types';
import { RouteNames, routes } from '@vkph/common/utils';

const longreadMap: Record<string, string> = {
  [LongreadTypes.Events]: 'EventsLongread',
  [LongreadTypes.News]: 'NewsLongread',
  [LongreadTypes.Post]: 'PostLongread',
  [LongreadTypes.Surveys]: 'SurveysLongread',
  [LongreadTypes.Timeline]: 'TimelineLongread',
};

type LongreadNavigationParams = {
  from?: string;
};

type LongreadQueryParams = {
  type: LongreadTypes;
  id: string;
};

export const LongreadRoute: FC = () => {
  const navigate = useNavigate();

  const { queryParams } = useQueryParams<LongreadNavigationParams>();
  const { from } = queryParams;
  const { type, id } = useParams<LongreadQueryParams>();

  const location = useLocation();
  const { longreadLocation, ...payload } = location.state || {};

  const onClose = () => {
    if (longreadLocation) {
      return navigate(-1);
    }

    return navigate(from || routes[RouteNames.Home]);
  };

  if (!type || !longreadMap[type]) {
    return (
      <PageOverlay visible onClose={onClose}>
        <UiEmpty.Feed emptyMessage={{ header: 'Произошла ошибка' }} />
      </PageOverlay>
    );
  }

  const modulePath = longreadMap[type];
  const moduleProps = { ...queryParams, ...payload, id, onClose };

  return (
    <PageOverlay key={type} visible onClose={onClose}>
      <DynamicRenderComponent modulePath={modulePath} moduleProps={moduleProps} />
    </PageOverlay>
  );
};
