import { Achievement, UiUser, RouterLink, UserAvatar } from '@vkph/components';
import {
  UiAvatarSize,
  UiDivider,
  UiList,
  UiModal,
  UiModalContext,
  UiSkeleton,
  UiSpace,
  UiTypography,
} from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, useContext, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';

import { useAbstractStorage } from '@vkph/common/hooks';
import { profileFullInfoStorage } from '@vkph/common/store/profile';
import { UserIdModel, GamificationItemTypes, GamificationThanks, Gender } from '@vkph/common/types/models';
import {
  createArrayMock,
  utcToLocalDate,
  getRoutePath,
  RouteNames,
  getFullNameWithoutPatronymic,
} from '@vkph/common/utils';
import { GamificationEmpty } from '~profile/components/gamification/empty/GamificationEmpty';
import { getGamificationUserThanksStorage } from '~profile/store/gamification';

const getGenderedActionByType = (type: GamificationItemTypes, gender: Gender) => {
  if (type === GamificationItemTypes.Thanks) {
    return gender === Gender.Female ? 'поблагодарила' : 'поблагодарил';
  }

  if (type === GamificationItemTypes.Badge) {
    return gender === Gender.Female ? 'получила достижение' : 'получил достижение';
  }

  return '';
};

export const GamificationList: FC = () => {
  const { onClose } = useContext(UiModalContext);
  const { id: userId = '' } = useParams<{ id: UserIdModel }>();

  const userThanksStorage = useMemo(() => getGamificationUserThanksStorage({ userId }), [userId]);
  const { data: userThanks, loading: isUserThanksLoading } = useAbstractStorage(userThanksStorage.storage, {
    autoFetchAndRefetch: true,
    resetStoreOnUnmount: true,
  });

  const { data: profileFullInfoData } = useStore(profileFullInfoStorage.storage.store);
  const titleSteps = { 1: { title: 'Благодарности', badgeValue: userThanks?.meta?.objectsTotal } };

  if (!profileFullInfoData) return null;

  const { main: toUser } = profileFullInfoData;
  const isProfileActive = toUser.isActive;

  const Subtitle: FC<{ thank: GamificationThanks }> = (subtitleProps) => {
    const { thank } = subtitleProps;
    const { createdAt, fromUser } = thank;

    return (
      <UiSpace size={4}>
        <UiTypography.Text type="secondary">{utcToLocalDate(createdAt, 'dd MMMM yyyy')}</UiTypography.Text>
        <UiTypography.Text type="secondary" strong>
          {getGenderedActionByType(GamificationItemTypes.Thanks, fromUser.gender)}
        </UiTypography.Text>
        <RouterLink to={getRoutePath(RouteNames.Profile, { id: toUser.id })}>
          {getFullNameWithoutPatronymic(toUser.fullName)}
        </RouterLink>
      </UiSpace>
    );
  };

  return (
    <>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={titleSteps} />
      </UiModal.Header>

      <UiModal.Content basePadding>
        {isUserThanksLoading && (
          <UiList
            grid={{ gutter: 16, column: 1 }}
            dataSource={createArrayMock(4, (_, i) => i)}
            renderItem={() => (
              <UiList.Item noStyle>
                <UiSpace direction="vertical" full>
                  <UiUser.Info.Skeleton size={UiAvatarSize.M} />
                  <UiSkeleton loading width="100%" height={180} />
                </UiSpace>
                <UiDivider overwriteBasePadding />
              </UiList.Item>
            )}
          />
        )}

        {!isUserThanksLoading && !userThanks?.items?.length && (
          <GamificationEmpty toggleAddThanksOpen={onClose} type={GamificationItemTypes.Thanks} />
        )}

        {toUser && !isUserThanksLoading && Boolean(userThanks?.items?.length) && (
          <UiList
            grid={{ gutter: 16, column: 1 }}
            dataSource={userThanks?.items}
            renderItem={(thank) => {
              const { fromUser, attributes } = thank;
              const { isActive } = fromUser;

              return (
                <UiList.Item noStyle>
                  <UiSpace direction="vertical" full size={20}>
                    <UiUser.Info
                      avatarProps={{ size: 40, src: fromUser.avatar }}
                      title={getFullNameWithoutPatronymic(fromUser.fullName)}
                      to={getRoutePath(RouteNames.Profile, { id: fromUser.id })}
                      subtitle={<Subtitle thank={thank} />}
                    />

                    <Achievement.Placeholder>
                      <Achievement
                        size={16}
                        align="center"
                        direction="vertical"
                        paragraph={thank.msg}
                        title={attributes.name}
                      >
                        <Achievement.Icon type="thank" src={attributes.image}>
                          <Link to={getRoutePath(RouteNames.Profile, { id: fromUser.id })} onClick={onClose}>
                            <UserAvatar src={fromUser.avatar} isActive={isActive} size={80} />
                          </Link>
                          <Link to={getRoutePath(RouteNames.Profile, { id: toUser.id })} onClick={onClose}>
                            <UserAvatar src={toUser.avatar} isActive={isProfileActive} size={80} />
                          </Link>
                        </Achievement.Icon>
                      </Achievement>
                    </Achievement.Placeholder>
                  </UiSpace>
                  <UiDivider overwriteBasePadding />
                </UiList.Item>
              );
            }}
          />
        )}
      </UiModal.Content>
    </>
  );
};
