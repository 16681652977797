import { PageNotFound } from '@vkph/components';
import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import {
  useCurrentProfile,
  currentProfileRolesPermissionsMapStore,
} from '@vkph/common/providers/current-profile';

export const AdminRoute: FC = () => {
  const currentProfile = useCurrentProfile();
  const currentProfilePermissionsMap = useStore(currentProfileRolesPermissionsMapStore);
  const isAccess = currentProfile.superAdmin || currentProfilePermissionsMap.size > 0;

  return isAccess ? <Outlet /> : <PageNotFound />;
};
