import { ErrorBoundary, ErrorBoundaryFallback, ErrorBoundaryFallbackProps } from '@vkph/components';
import { DynamicModuleProps, DynamicRenderComponent, PageOverlay } from '@vkph/modules';
import { UiDrawer, UiModal, UiModalTypes, UiTypography } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { useNavigationType, useLocation, NavigationType } from 'react-router-dom';

import { closeAllGlobalModals, closeGlobalModal, globalModalsStore } from '@vkph/common/store/global-modals/';
import {
  GlobalModalAppearances,
  GlobalModalNames,
  globalModalsSettings,
  GlobalModalState,
} from '@vkph/common/store/global-modals/modal-types';

const appearanceMap = new Map<GlobalModalAppearances, UiModalTypes>([
  [GlobalModalAppearances.LargeModal, UiModalTypes.Large],
  [GlobalModalAppearances.LargeAlternativeModal, UiModalTypes.LargeAlternative],
  [GlobalModalAppearances.MediumModal, UiModalTypes.Medium],
  [GlobalModalAppearances.FullscreenModal, UiModalTypes.FullScreen],
]);

const renderModalContent = (modulePath: string, moduleProps: DynamicModuleProps) => (
  <DynamicRenderComponent modulePath={modulePath} moduleProps={moduleProps} />
);

// TODO, расширить возможность передачи пропсов модалкам https://jira.mail.ru/browse/B2BCORE-1403
type Payload = { onClose?: () => void };

const renderGlobalModal = (modalName: GlobalModalNames, modalState: GlobalModalState<Payload>) => {
  const settings = globalModalsSettings[modalName];

  if (!settings) {
    console.error(`Настройки для глобального окна ${modalName} не найдены`);

    return null;
  }

  const { appearance, moduleName } = settings;
  const modalContent = renderModalContent(moduleName, (modalState.payload || {}) as DynamicModuleProps);
  const onCloseGlobalModal = () => closeGlobalModal(modalName);
  const onClose = modalState.payload?.onClose;
  const FallbackComponent: FC<ErrorBoundaryFallbackProps> = (errorProps) => (
    <ErrorBoundaryFallback
      {...errorProps}
      description={<UiTypography.Link onClick={onClose || onCloseGlobalModal}>Закрыть</UiTypography.Link>}
    />
  );

  if (appearance === GlobalModalAppearances.PageOverlay) {
    return (
      <PageOverlay key={modalName} visible={modalState.isVisible} onClose={onClose || onCloseGlobalModal}>
        <ErrorBoundary FallbackComponent={FallbackComponent}>{modalContent}</ErrorBoundary>
      </PageOverlay>
    );
  }

  if (appearance === GlobalModalAppearances.PageOverlayNoClose) {
    return (
      <PageOverlay key={modalName} visible={modalState.isVisible}>
        <ErrorBoundary>{modalContent}</ErrorBoundary>
      </PageOverlay>
    );
  }

  if (appearance === GlobalModalAppearances.Drawer) {
    return (
      <UiDrawer key={modalName} width={860} closable open={modalState.isVisible} onClose={onCloseGlobalModal}>
        <ErrorBoundary>{modalContent}</ErrorBoundary>
      </UiDrawer>
    );
  }

  const modalType = appearanceMap.get(appearance);

  if (!modalType) {
    // eslint-disable-next-line no-console
    console.error(`Тип глобального окна ${modalName} не найден`);

    return null;
  }

  if (
    appearance === GlobalModalAppearances.LargeModal ||
    appearance === GlobalModalAppearances.LargeAlternativeModal ||
    appearance === GlobalModalAppearances.MediumModal ||
    appearance === GlobalModalAppearances.FullscreenModal
  ) {
    return (
      <UiModal
        key={modalName}
        type={modalType}
        isOpen={modalState.isVisible}
        onClose={onClose || onCloseGlobalModal}
      >
        <ErrorBoundary FallbackComponent={FallbackComponent}>{modalContent}</ErrorBoundary>
      </UiModal>
    );
  }

  return null;
};

export const GlobalModals = () => {
  const globalModals = useStore(globalModalsStore);
  const location = useLocation();
  const navigationType = useNavigationType();

  useEffect(closeAllGlobalModals, [location.pathname]);

  useEffect(() => {
    if (navigationType === NavigationType.Pop) {
      closeAllGlobalModals();
    }
  }, [navigationType]);

  return (
    <>
      {Object.entries(globalModals).map(([modalName, modalState]) =>
        modalState
          ? renderGlobalModal(modalName as GlobalModalNames, modalState as GlobalModalState<Payload>)
          : null,
      )}
    </>
  );
};
