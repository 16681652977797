"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendMessageToDispatcherAsync = exports.sendMessageToDispatcher = exports.messageType = void 0;
var uuid_1 = require("uuid");
var REJECT_TIMEOUT_DEFAULT = 30000;
exports.messageType = Object.freeze({
    authToken: 'authToken',
    colorScheme: 'colorScheme',
    currentUser: 'currentUser',
});
var messageTypes = Object.values(exports.messageType);
var getUuid = function () { return window.name; };
var sendMessageToDispatcher = function (params) {
    var _a;
    var message = __assign(__assign({ messageUuid: (0, uuid_1.v4)() }, params), { widgetUuid: getUuid() });
    (_a = window === null || window === void 0 ? void 0 : window.top) === null || _a === void 0 ? void 0 : _a.postMessage(message, '*');
};
exports.sendMessageToDispatcher = sendMessageToDispatcher;
var sendMessageToDispatcherAsync = function (params) {
    var _a = params.rejectTimeout, rejectTimeout = _a === void 0 ? REJECT_TIMEOUT_DEFAULT : _a, rest = __rest(params, ["rejectTimeout"]);
    var messageUuid = (0, uuid_1.v4)();
    var message = __assign({ widgetUuid: getUuid(), messageUuid: messageUuid }, rest);
    return new Promise(function (resolve, reject) {
        var _a;
        (_a = window === null || window === void 0 ? void 0 : window.top) === null || _a === void 0 ? void 0 : _a.postMessage(message, '*');
        window.addEventListener('message', function (event) {
            var data = event.data;
            var currentMessageType = data.messageType, currentMessageUuid = data.messageUuid;
            var isMatchMessageType = Boolean(exports.messageType[currentMessageType]);
            var isMatchMessageUuid = currentMessageUuid === messageUuid;
            if (isMatchMessageType && isMatchMessageUuid) {
                resolve(data);
            }
        });
        setTimeout(function () { return reject(messageUuid); }, rejectTimeout);
    });
};
exports.sendMessageToDispatcherAsync = sendMessageToDispatcherAsync;
