import { UiCard, UiIcon, UiTypography } from '@vkph/ui';
import React, { FC, useCallback } from 'react';

import { closeGlobalModal, openGlobalModal } from '@vkph/common/store/global-modals';
import { GlobalModalNames } from '@vkph/common/store/global-modals/modal-types';
import DocumentSvg from '@vkph/ui/svg/document.svg';

export const UserAgreement: FC = () => {
  const onClickOpenUserAgreement = useCallback(() => {
    openGlobalModal(GlobalModalNames.ProfilePreviewUserAgreementCurrent, {
      onClose: () => closeGlobalModal(GlobalModalNames.ProfilePreviewUserAgreementCurrent),
    });
  }, []);

  return (
    <UiCard size="default">
      <UiIcon.Label component={DocumentSvg}>
        <UiTypography.Link onClick={onClickOpenUserAgreement}>Пользовательское соглашение</UiTypography.Link>
      </UiIcon.Label>
    </UiCard>
  );
};
