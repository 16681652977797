import { WidgetUuid } from '@vkph/widget-sdk';
import React, { CSSProperties, FC, IframeHTMLAttributes, useEffect } from 'react';

import { registry } from '../registry';

type BaseProps = Pick<IframeHTMLAttributes<HTMLIFrameElement>, 'style'>;

export interface WidgetProps extends BaseProps {
  url: string;
  uuid: WidgetUuid;
  onLoad?: (uuid: WidgetUuid) => void;
  onError?: (uuid: WidgetUuid) => void;
}

export const Widget: FC<WidgetProps> = (props) => {
  const { url, uuid, onLoad, onError, style } = props;

  const onLoadIFrame = () => {
    onLoad?.(uuid);
  };

  const onErrorIFrame = () => {
    onError?.(uuid);
  };

  useEffect(() => {
    registry.add(uuid);

    return () => {
      registry.delete(uuid);
    };
  }, []);

  const mergedStyle: CSSProperties = {
    // TODO https://jira.vk.team/browse/B2BCORE-8676
    width: '100%',
    height: '720px',
    border: 'none',
    ...style,
  };

  return (
    <iframe
      title=" "
      name={uuid}
      src={url}
      id={`uuid-${uuid}`}
      style={mergedStyle}
      onLoad={onLoadIFrame}
      onError={onErrorIFrame}
    />
  );
};
