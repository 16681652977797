import { AxiosError } from 'axios';
import { createEffect, createEvent } from 'effector';

import { endpoints } from '@vkph/common/endpoints';
import { UserIdParams } from '@vkph/common/store/profile';
import { buildEndpointWithQueryParams, abstractStorageFactory } from '@vkph/common/utils';
import { SubscribedEvent } from '~profile/components/profile/Profile';
import {
  FollowUser,
  FollowUserParams,
  FollowUserPaginationParams,
  SubscribedIdsParams,
} from '~profile/typings/follow-user';
import {
  AllRelationsCounterModel,
  FollowListResponse,
  SubscribedStatus,
  SubscribedStatusResponse,
} from '~profile/typings/models/follow-user.model';

import { timelinesUserSubscribe, timelinesUserUnsubscribe } from './api';

export enum SocialsInfoType {
  Followers = 'followers',
  Followings = 'followings',
}

export const followSubscribedUserEffect = createEffect<FollowUserParams, unknown, AxiosError>(
  ({ type, userId }) =>
    type === SubscribedEvent.Add ? timelinesUserSubscribe(userId) : timelinesUserUnsubscribe(userId),
);

export const getTimelinesFollowingIsSubscribedStorage = () => {
  const storage = abstractStorageFactory<
    SubscribedStatusResponse,
    SubscribedStatus[],
    SubscribedStatus[],
    SubscribedIdsParams
  >({
    endpointBuilder: () => endpoints.timelines.followingIsSubscribed(),
    defaultValue: [],
    dataMapper: ({ items }) => items,
    dataBuilder: (params) => params,
    requestMethod: 'post',
  });

  storage.store.on(followSubscribedUserEffect.done, (state) => ({
    ...state,
    data: [
      {
        ...state.data[0],
        isActive: !state.data[0].isActive,
      },
    ],
  }));

  return { storage };
};

export const getFollowListStorage = () => {
  const storage = abstractStorageFactory<
    FollowListResponse,
    FollowUser[],
    FollowUser[],
    FollowUserPaginationParams
  >({
    endpointBuilder: ({ userId, type, pageNumber, pageSize }) =>
      buildEndpointWithQueryParams(
        type === SocialsInfoType.Followers
          ? endpoints.timelines.usersIdFollowers(userId)
          : endpoints.timelines.usersIdFollowings(userId),
        { pageNumber, pageSize },
      ),
    defaultValue: [],
    shouldAppendData: true,
    cancelPendingRequestOnFetch: true,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.pagesCount, page: meta.pageNumber }),
    dataMapper: ({ items }) =>
      items.map((item) => ({
        ...item.user,
        isSubscribed: item.isSubscribed,
        id: String(item.user.keycloakUser?.keycloakId),
      })),
  });

  const followUserEffect = createEffect<FollowUser, unknown, AxiosError>((item) =>
    timelinesUserSubscribe(item.id),
  );

  const unfollowUserEffect = createEffect<FollowUser, unknown, AxiosError>((item) =>
    timelinesUserUnsubscribe(item.id),
  );

  storage.store.on([followUserEffect.done, unfollowUserEffect.done], (state, { params }) => ({
    ...state,
    data: state.data.map((item) => ({
      ...item,
      isSubscribed: item.id === params.id ? !item.isSubscribed : item.isSubscribed,
    })),
  }));

  return { storage, followUserEffect, unfollowUserEffect };
};

export const getTimelinesAllRelationsCounterStorage = () => {
  const storage = abstractStorageFactory<
    AllRelationsCounterModel,
    AllRelationsCounterModel,
    AllRelationsCounterModel,
    UserIdParams
  >({
    endpointBuilder: ({ userId }) => endpoints.timelines.usersIdAllRelationsCounter(userId),
    defaultValue: { followers: 0, followings: 0 },
  });

  const followingsCountEvent = createEvent<{ type: string }>();

  storage.store.on(followSubscribedUserEffect, (state, params) => ({
    ...state,
    data: {
      ...state.data,
      followers: params.type === SubscribedEvent.Add ? state.data.followers + 1 : state.data.followers - 1,
    },
  }));

  storage.store.on(followingsCountEvent, (state, params) => ({
    ...state,
    data: {
      ...state.data,
      followings: params.type === SubscribedEvent.Add ? state.data.followings + 1 : state.data.followings - 1,
    },
  }));

  return { storage, followingsCountEvent };
};

export type GetTimelinesAllRelationsCounterStorage = ReturnType<
  typeof getTimelinesAllRelationsCounterStorage
>;
