import { AxiosResponse } from 'axios';

import { endpoints } from '@vkph/common/endpoints';
import { UserIdModel, GamificationBadgeId } from '@vkph/common/types/models';
import { api } from '@vkph/common/utils';

export type CreateThanksParams = {
  msg: string;
  toUser: UserIdModel;
  typeRid: string | number;
  coins?: number;
};

export type SendBadgeParams = {
  badge: GamificationBadgeId;
  user: UserIdModel;
  coins: number;
};

export const createThanks = <T = unknown>({
  msg,
  toUser,
  typeRid,
  coins,
}: CreateThanksParams): Promise<AxiosResponse<T>> =>
  api.post({
    url: endpoints.gamification.thanksCreate(),
    data: { msg, toUser, typeRid, coins },
  });

export const sendBadge = <T = unknown>(data: SendBadgeParams): Promise<AxiosResponse<T>> =>
  api.post({
    url: endpoints.gamification.badgesSend(),
    data,
  });
