import { UiUser } from '@vkph/components';
import {
  UiAvatarSize,
  UiButton,
  UiCard,
  UiList,
  UiTruncateMarkup,
  UiTypography,
  UiTypographySymbolName,
} from '@vkph/ui';
import { isWithinInterval, isSameDay } from 'date-fns';
import React, { FC, useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAbstractStorage } from '@vkph/common/hooks';
import { SubstitutionModel } from '@vkph/common/types/models';
import { RouteNames, getRoutePath, getFullNameWithoutPatronymic, authService } from '@vkph/common/utils';
import { getProfileSubstitutionStorage } from '~profile/store/substitution';

import { AbsenceHeader } from './header/AbsenceHeader';

const SHOW_ACTIVITIES_COUNT = 4;

export const enum SubstitutionType {
  Illness = 'illness',
  Vacation = 'vacation',
  BusinessTrip = 'business_trip',
}

export const Absence: FC = () => {
  const [showMore, setShowMore] = useState(true);
  const { id = '' } = useParams<{ id: string }>();
  const currentUserId = authService.getCurrentUserId();
  const userId = id ?? currentUserId;
  const profileSubstitutionStorage = useMemo(() => getProfileSubstitutionStorage({ userId }), [userId]);

  const { data: substitution } = useAbstractStorage(profileSubstitutionStorage.storage, {
    autoFetchAndRefetch: Boolean(userId),
  });

  const activeSubstitution = useMemo(() => {
    return (
      substitution
        .filter((item: SubstitutionModel) => {
          return (
            isSameDay(new Date(item.since), new Date(item.till)) ||
            isWithinInterval(new Date(), { start: new Date(item.since), end: new Date(item.till) })
          );
        })
        .find((item) => {
          return (
            item.type === SubstitutionType.Illness ||
            item.type === SubstitutionType.Vacation ||
            item.type === SubstitutionType.BusinessTrip
          );
        }) ?? null
    );
  }, [substitution]);

  const activities = activeSubstitution?.activities;

  useEffect(() => {
    if (activeSubstitution) {
      setShowMore(SHOW_ACTIVITIES_COUNT >= Number(activeSubstitution.activities?.length));
    }
  }, [activeSubstitution]);

  if (!activeSubstitution) return null;

  return (
    <UiCard emptyPadding>
      <AbsenceHeader substitution={activeSubstitution} />
      <UiCard.Content style={{ padding: '20px 24px' }}>
        <UiTypography.Title type="secondary" level={4} style={{ marginBottom: 16 }}>
          Меня замещают:
        </UiTypography.Title>

        <UiUser.Info
          avatarProps={{ src: activeSubstitution.substitute.smallAvatar, size: UiAvatarSize.M }}
          title={getFullNameWithoutPatronymic(activeSubstitution.substitute.fullName)}
          to={getRoutePath(RouteNames.Profile, { id: activeSubstitution.substitute.id })}
          spaceProps={{ align: 'start' }}
          subtitle={
            <>
              {activities && activities.length > 0 && (
                <>
                  <UiList
                    grid={{ column: 1 }}
                    dataSource={activities.slice(0, showMore ? activities.length : SHOW_ACTIVITIES_COUNT)}
                    renderItem={(item) => (
                      <UiTypography.Text key={item} type="secondary">
                        <UiTruncateMarkup truncate>
                          <UiTruncateMarkup.Atom>
                            <UiTypography.Symbol name={UiTypographySymbolName.Bullet} />
                            <UiTypography.Symbol name={UiTypographySymbolName.NBSP} />
                          </UiTruncateMarkup.Atom>
                          {item}
                        </UiTruncateMarkup>
                      </UiTypography.Text>
                    )}
                  />

                  {!showMore && (
                    <UiButton
                      type="link"
                      label={`Ещё ${activities.length - SHOW_ACTIVITIES_COUNT}`}
                      onClick={() => setShowMore(true)}
                    />
                  )}
                </>
              )}
              {!activities?.length && <UiTypography.Text>по всем вопросам</UiTypography.Text>}
            </>
          }
        />
      </UiCard.Content>
    </UiCard>
  );
};
