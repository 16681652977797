import { ErrorBoundary } from '@vkph/components';
import { Layout } from '@vkph/ui';
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { useAbstractStorage } from '@vkph/common/hooks';
import {
  currentProfileRolesPermissionsStorage,
  useCurrentProfile,
} from '@vkph/common/providers/current-profile';
import { ServiceRolesNames } from '@vkph/common/types';
import { Header } from '@vkph/modules/components/header';
import { HeaderErrorFallback } from '@vkph/modules/components/header/error';
import { Navbar } from '@vkph/modules/components/navbar';

export const PrivateRoute: FC = () => {
  const { id: currentProfileId } = useCurrentProfile();

  const { loading: isCurrentProfileRolesPermissionsLoading } = useAbstractStorage(
    currentProfileRolesPermissionsStorage,
    {
      autoFetchAndRefetch: true,
      autoFetchParams: {
        userId: currentProfileId,
        groupBy: ServiceRolesNames.Events,
        serviceName: Object.values(ServiceRolesNames),
      },
    },
  );

  if (isCurrentProfileRolesPermissionsLoading) {
    return <Layout.Loading />;
  }

  return (
    <Layout
      header={
        <ErrorBoundary FallbackComponent={HeaderErrorFallback}>
          <Header />
        </ErrorBoundary>
      }
      navbar={<Navbar />}
    >
      <Outlet />
    </Layout>
  );
};
