import { RouteNames, PagesMapping } from '@vkph/common/utils';

import { PageAdminAdministration } from '../layouts/mail/admin-administration/AdminAdministration';
import { PageAdminContentManagementSurveyAnalytics } from '../layouts/mail/admin-content-management-analytics/AdminContentManagementAnalyticsPage';
import { PageAdminContentManagementCmsPageFeedbackView } from '../layouts/mail/admin-content-management-cms-page-feedback-view/AdminContentManagementCmsPageFeedbackView';
import { PageAdminContentManagementGroupView } from '../layouts/mail/admin-content-management-group-view/AdminContentManagementGroupView';
import { PageAdminContentManagementGroupsPages } from '../layouts/mail/admin-content-management-groups-pages/AdminContentManagementGroupsPages';
import { PageAdminContentManagement } from '../layouts/mail/admin-content-management/AdminContentManagement';
import { PageAdminHealthCheck } from '../layouts/mail/admin-health-check/AdminHealthCheck';
import { PageAdminOrgStructureDefault } from '../layouts/mail/admin-org-structure/AdminOrgStructure@default';
import { PageAdvancedSearch } from '../layouts/mail/advanced-search/PageAdvancedSearch';
import { PageAdvancedSearchFileView } from '../layouts/mail/advanced-search/file-view/PageAdvancedSearchFileView';
import { PageBlog } from '../layouts/mail/blog/PageBlog';
import { PageBlogFile } from '../layouts/mail/blog/file/PageBlogFile';
import { PageBlogFolder } from '../layouts/mail/blog/folder/PageBlogFolder';
import { PageBlogSearch } from '../layouts/mail/blog/search/PageBlogSearch';
import { PageBlogs } from '../layouts/mail/blogs/PageBlogs';
import { PageCalendar } from '../layouts/mail/calendar/PageCalendar';
import { PageCmsView } from '../layouts/mail/cms/PageCmsView';
import { PageDraftIdeas } from '../layouts/mail/draft-ideas/PageDraftIdeas';
import { PageEvents } from '../layouts/mail/events/PageEvents';
import { PageFavorites } from '../layouts/mail/favorites/PageFavorites';
import { PageFile } from '../layouts/mail/file/PageFile';
import { PageFolder } from '../layouts/mail/folder/PageFolder';
import { PageHome } from '../layouts/mail/home/PageHome';
import { PageIdea } from '../layouts/mail/ideas/PageIdea';
import { PageIdeas } from '../layouts/mail/ideas/PageIdeas';
import { PageMeetingRooms } from '../layouts/mail/meeting-rooms/PageMeetingRooms';
import { PageNewsAdapter } from '../layouts/mail/news/PageNewsAdapter';
import { PageProfileEdit } from '../layouts/mail/profile-edit/PageProfileEdit';
import { PageProfileRewardsProgress } from '../layouts/mail/profile-rewards-progress/PageProfileRewardsProgress';
import { PageProfile } from '../layouts/mail/profile/PageProfile';
import { PageProfileFile } from '../layouts/mail/profile/file/PageProfileFile';
import { PageProfileFolder } from '../layouts/mail/profile/folder/PageProfileFolder';
import { PageProjectFile } from '../layouts/mail/project/file/PageProjectFile';
import { PageProjectFolder } from '../layouts/mail/project/folder/PageProjectFolder';
import { PageProjects } from '../layouts/mail/projects/PageProjects';
import { PageSearchAdapter } from '../layouts/mail/search/PageSearchAdapter';
import { PageSignIn } from '../layouts/mail/sign-in/SignInPage';
import { PageStructureAdapter } from '../layouts/mail/structure/PageStructureAdapter';
import { PageAdminSummaryAnalytics } from '../layouts/mail/summary-analytics/PageSummaryAnalytics';
import { PageSurveys } from '../layouts/mail/surveys/PageSurveys';
import { PageProjectView } from '../layouts/mail/tasks-projects-view/PageTasksProjectsView';
import { PageTasks } from '../layouts/mail/tasks/PageTasks';
import { PageVKTeams } from '../layouts/mail/vk-teams/PageVKTeams';
import { PageVKHRTek } from '../layouts/mail/vkhr-tek/PageVKHRTek';

export const adminPrivatePagesMap: Partial<PagesMapping> = {
  [RouteNames.AdminOrgStructure]: PageAdminOrgStructureDefault,
  [RouteNames.AdminContentManagement]: PageAdminContentManagement,
  [RouteNames.AdminContentManagementGroupsPages]: PageAdminContentManagementGroupsPages,
  [RouteNames.AdminContentManagementGroupView]: PageAdminContentManagementGroupView,
  [RouteNames.AdminContentManagementCmsPageFeedbackView]: PageAdminContentManagementCmsPageFeedbackView,
  [RouteNames.AdminContentManagementSurveysAnalyticsView]: PageAdminContentManagementSurveyAnalytics,
  [RouteNames.AdminAdministration]: PageAdminAdministration,
  [RouteNames.AdminHealthCheck]: PageAdminHealthCheck,
  [RouteNames.AdminSummaryAnalytics]: PageAdminSummaryAnalytics,
};

export const publicPagesMap: Partial<PagesMapping> = {
  [RouteNames.SignIn]: PageSignIn,
};

export const pagesMap: Partial<PagesMapping> = {
  [RouteNames.Home]: PageHome,
  [RouteNames.Search]: PageSearchAdapter,

  // ~filestorage
  [RouteNames.Folder]: PageFolder,
  [RouteNames.File]: PageFile,

  // ~advanced
  [RouteNames.AdvancedSearch]: PageAdvancedSearch,
  [RouteNames.AdvancedSearchFileView]: PageAdvancedSearchFileView,

  // ~calendar
  [RouteNames.Calendar]: PageCalendar,

  // ~cms

  [RouteNames.CmsView]: PageCmsView,

  // ~groups
  [RouteNames.Events]: PageEvents,
  [RouteNames.GroupsList]: PageBlogs,
  [RouteNames.GroupView]: PageBlog,
  [RouteNames.GroupFolder]: PageBlogFolder,
  [RouteNames.GroupFile]: PageBlogFile,
  [RouteNames.GroupSearch]: PageBlogSearch,

  // ~meeting-rooms
  [RouteNames.MeetingRooms]: PageMeetingRooms,

  // ~news
  [RouteNames.News]: PageNewsAdapter,

  // ~profile
  [RouteNames.Profile]: PageProfile,
  [RouteNames.ProfileEdit]: PageProfileEdit,
  [RouteNames.ProfileRewardsProgress]: PageProfileRewardsProgress,
  [RouteNames.ProfileFolder]: PageProfileFolder,
  [RouteNames.ProfileFile]: PageProfileFile,

  // ~structure
  [RouteNames.Structure]: PageStructureAdapter,

  // ~surveys
  [RouteNames.Surveys]: PageSurveys,

  // ~ideas
  [RouteNames.Idea]: PageIdea,
  [RouteNames.Ideas]: PageIdeas,
  [RouteNames.IdeasDraft]: PageDraftIdeas,

  // ~tasks
  [RouteNames.Tasks]: PageTasks,
  [RouteNames.Projects]: PageProjects,
  [RouteNames.ProjectsView]: PageProjectView,
  [RouteNames.ProjectsFolder]: PageProjectFolder,
  [RouteNames.ProjectsFile]: PageProjectFile,

  // ~favorites
  [RouteNames.Favorites]: PageFavorites,

  // VK Services
  [RouteNames.VKHRTek]: PageVKHRTek,
  [RouteNames.VKTeams]: PageVKTeams,
};
