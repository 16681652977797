import { PageNotFound, EmployeeContacts } from '@vkph/components';
import { ProfileTimeslots, SkillsGroup } from '@vkph/modules';
import { Layout, UiCol, UiIcon, UiRow, UiSpace, useBreakpoint, useLayout } from '@vkph/ui';
import { useStoreMap } from 'effector-react';
import React, { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useSettingsConfig } from '@vkph/common/providers/settings-config';
import { profileFullInfoStorage } from '@vkph/common/store/profile';
import { UserIdModel } from '@vkph/common/types/models';
import { getRoutePath, RouteNames } from '@vkph/common/utils';
import { AbsolutePlacement, useAbsolutePlacementShift, useSpace } from '@vkph/ui/hooks';
import { useTheme } from '@vkph/ui/providers/theme';
import SunSvg from '@vkph/ui/svg/sun.svg';
import { ProfilePageProps } from '~profile/components/ProfilePage';
import { Absence } from '~profile/components/absence/Absence';
import { ProfileBreadcrumb } from '~profile/components/breadcrumb/ProfileBreadcrumb';
import { Gamification } from '~profile/components/gamification/Gamification';
import { Profile } from '~profile/components/profile/Profile';
import { UserAgreement } from '~profile/components/user-agreement/UserAgreement';
import { getGamificationUserThanksSummaryStorage } from '~profile/store/gamification';

import { EmployeeContactsQRCard } from './employee-contact-qr-card/EmployeeContactsQRCard';
import { RelationsCard } from './relations-card/RelationsCard';

const defaultBreadcrumbsRoutes = [
  {
    path: getRoutePath(RouteNames.Home),
    title: 'Главная',
  },
];

export const ProfilePage: FC<ProfilePageProps> = (props) => {
  const { ProfileTimeline, breadcrumbs = defaultBreadcrumbsRoutes } = props;
  const { id: userId = '' } = useParams<{ id: UserIdModel }>();
  const { innerGutter, outerGutter } = useLayout();
  const breakpoints = useBreakpoint();
  const { spaceXS, spaceL } = useSpace();

  const userThanksStorage = useMemo(() => getGamificationUserThanksSummaryStorage({ userId }), [userId]);
  const [{ variables: themeVariables }] = useTheme();
  const { config } = useSettingsConfig();
  const isProfileCalendarEnabled = config.layouts?.profileCalendarEnabled?.value;

  const { isUserActive, userError } = useStoreMap(
    profileFullInfoStorage.storage.store,
    ({ data, error: fetchError }) => ({
      isUserActive: Boolean(data?.main?.isActive),
      userError: fetchError,
    }),
  );

  const { placementCls, placementStyles } = useAbsolutePlacementShift({
    placement: AbsolutePlacement.TopCenter,
  });

  if (userError) {
    return (
      <>
        <ProfileBreadcrumb items={breadcrumbs} />
        <PageNotFound
          icon={<UiIcon component={SunSvg} width={120} height={104} />}
          emptyMessage={{ header: 'Профиль не существует' }}
        />
      </>
    );
  }

  return (
    <>
      <ProfileBreadcrumb items={breadcrumbs} />

      {!breakpoints.lg && (
        <div
          className={placementCls}
          style={{
            ...placementStyles,
            width: '100%',
            height: '148px',
            zIndex: 0,
            backgroundColor: themeVariables.colorBrand,
          }}
        />
      )}

      {breakpoints.lg && isProfileCalendarEnabled && (
        <UiCol style={{ margin: `${innerGutter} ${outerGutter} 0 ${outerGutter}` }}>
          <ProfileTimeslots isUserActive={isUserActive} />
        </UiCol>
      )}
      <Layout.Main>
        <Layout.Content>
          <Profile userThanksStorage={userThanksStorage} />

          {!breakpoints.lg && (
            <>
              <EmployeeContacts userId={userId} style={{ marginBottom: spaceXS }}>
                <EmployeeContactsQRCard userId={userId} />
              </EmployeeContacts>
              {isUserActive && <RelationsCard />}
            </>
          )}

          <ProfileTimeline />
        </Layout.Content>
        <Layout.Sider>
          <UiRow
            style={{ gap: spaceL, flexDirection: breakpoints.xxl ? 'row' : 'column' }}
            align="top"
            wrap={false}
          >
            <UiSpace size={spaceL} direction="vertical">
              <Absence />
              <EmployeeContacts userId={userId}>
                <EmployeeContactsQRCard userId={userId} />
              </EmployeeContacts>
            </UiSpace>
            <UiSpace size={spaceL} direction="vertical">
              <SkillsGroup />
              <Gamification userThanksStorage={userThanksStorage} />
              {isUserActive && <UserAgreement />}
            </UiSpace>
          </UiRow>
        </Layout.Sider>
      </Layout.Main>
    </>
  );
};
