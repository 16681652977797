import { endpoints } from '@vkph/common/endpoints';
import { UserIdModel } from '@vkph/common/types/models';
import { api } from '@vkph/common/utils';

export const timelinesUserSubscribe = (userId: UserIdModel) =>
  api.post({
    url: endpoints.timelines.usersIdFollow(userId),
  });

export const timelinesUserUnsubscribe = (userId: UserIdModel) =>
  api.patch({
    url: endpoints.timelines.usersIdUnfollow(userId),
  });
