import { MessageData, WidgetUuid } from '@vkph/widget-sdk';

import { registry } from './registry';

interface SendMessageToWidget<T> extends MessageData<T> {
  widgetUuid: WidgetUuid;
}

export const sendMessageToWidget = <T>(message: SendMessageToWidget<T>) => {
  const { widgetUuid } = message;
  const iframeWidget = document.querySelector<HTMLIFrameElement>(`iframe#uuid-${widgetUuid}`);

  iframeWidget?.contentWindow?.postMessage(message, '*');
};

type SendMessageAllWidgets<T> = MessageData<T>;

export const sendMessageAllWidgets = <T>(params: SendMessageAllWidgets<T>) => {
  registry.forEach((widgetUuid) => sendMessageToWidget({ ...params, widgetUuid }));
};
