import { PageNotFound, ErrorBoundary, ScrollToTopButton } from '@vkph/components';
import React, { FC } from 'react';
import { Navigate, Route, Routes, useLocation, useMatch } from 'react-router-dom';

import { LONGREAD_ROUTE_PATH } from '@vkph/common/hooks';
import { buildRoutesFromConfig, RouteNames, routes } from '@vkph/common/utils';

import { GlobalModals } from '../../global-modals';
import { UserAgreementGuard } from '../../user-agreement-guard/UserAgreementGuard';
import { AdminRoute } from '../admin-route';
import { LongreadRoute } from '../longread-route';
import { adminPrivatePagesMap, pagesMap, publicPagesMap } from '../pages';
import { PrivateRoute } from '../private-route';

export const Router: FC = () => {
  const location = useLocation();
  const { longreadLocation } = location.state || {};
  const isLongread = useMatch(LONGREAD_ROUTE_PATH);

  return (
    <ErrorBoundary>
      {(!isLongread || longreadLocation) && (
        <Routes location={longreadLocation || location}>
          {buildRoutesFromConfig(publicPagesMap)}
          <Route Component={PrivateRoute}>
            <Route path="/" element={<Navigate to={routes[RouteNames.Home]} replace />} />
            {buildRoutesFromConfig(pagesMap)}
            <Route Component={AdminRoute}>{buildRoutesFromConfig(adminPrivatePagesMap)}</Route>
          </Route>
          <Route path="*" Component={PageNotFound} />
        </Routes>
      )}
      {isLongread && (
        <Routes>
          <Route path={LONGREAD_ROUTE_PATH} Component={LongreadRoute} />
        </Routes>
      )}
      <GlobalModals />
      <UserAgreementGuard />
      <ScrollToTopButton />
    </ErrorBoundary>
  );
};
