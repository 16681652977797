import { Layout } from '@vkph/ui';
import { useStore } from 'effector-react';
import isEmpty from 'lodash/isEmpty';
import React, { FC, PropsWithChildren, useCallback, useEffect, useMemo } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks/useAbstractStorage';
import { useLocalStorage } from '@vkph/common/hooks/useLocalStorage';
import { headerLogoImageStorage } from '@vkph/common/store/header-logo-image';
import { themeStorage } from '@vkph/common/store/theme';
import {
  ThemeOptions,
  ThemeProvider,
  ThemeProviderProps,
  ThemeVariables,
  variables,
} from '@vkph/ui/providers/theme';

const THEME_VARIABLES = 'themeVariables';
const initialTheme = {
  name: process.env.CUSTOMER || 'b2bcore',
  varsPrefix: ['--', process.env.CUSTOMER, process.env.CUSTOMER && '-', THEME_VARIABLES].join(''),
  variables: variables[THEME_VARIABLES] as ThemeVariables,
};

export const ThemeService: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const [userTheme, setUserTheme] = useLocalStorage<ThemeOptions>(
    initialTheme.varsPrefix,
    {} as ThemeOptions,
  );

  const { loading: isThemeLoading, error: themeError } = useAbstractStorage(themeStorage.storage, {
    autoFetchAndRefetch: true,
  });

  useAbstractStorage(headerLogoImageStorage.storage, {
    autoFetchAndRefetch: true,
  });

  const { colors, isUserColorsFilled } = useStore(themeStorage.themeState);

  const isUserThemeExist = !isEmpty(userTheme);
  const isThemeError = isEmpty(themeError);
  const isShowRouter = isUserThemeExist || isThemeError;

  const theme = useMemo<ThemeOptions>(() => {
    const themeDefault = !userTheme?.varsPrefix ? initialTheme : userTheme;
    const themeVariables = { ...themeDefault.variables };

    if (isUserColorsFilled) {
      colors.forEach(({ name, color }) => {
        themeVariables[name] = color;
      });
    }

    return { ...themeDefault, variables: themeVariables };
  }, [userTheme, colors, isUserColorsFilled]);

  const onChangeTheme = useCallback<ThemeProviderProps['onChange']>(
    ({ variables: themeVariables, ...rest }) => {
      setUserTheme({
        ...rest,
        variables: { ...userTheme?.variables, ...themeVariables },
      });
    },
    [userTheme],
  );

  useEffect(() => {
    if (isUserColorsFilled) {
      setUserTheme(theme);
    }
  }, [isUserColorsFilled]);

  return (
    <ThemeProvider theme={theme} onChange={onChangeTheme}>
      {isShowRouter && children}
      {!isUserThemeExist && <Layout.Loading spinning={isThemeLoading} />}
    </ThemeProvider>
  );
};
