import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { endpoints } from '@vkph/common/endpoints';
import { UserIdParams } from '@vkph/common/store/profile';
import { SubstitutionModel } from '@vkph/common/types/models';
import { abstractStorageFactory } from '@vkph/common/utils';

import { putProfileSubstitution, SubstitutionParams } from './api';

export const getProfileSubstitutionStorage = ({ userId }: UserIdParams) => {
  const storage = abstractStorageFactory<SubstitutionModel, SubstitutionModel[], SubstitutionModel[]>({
    endpointBuilder: () => endpoints.profile.userIdSubstitutions(userId),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  const putProfileSubstitutionEffect = createEffect<SubstitutionParams, SubstitutionModel[], AxiosError>(
    (params) => putProfileSubstitution<SubstitutionModel[]>(params).then(({ data }) => data),
  );

  storage.store.on(putProfileSubstitutionEffect.doneData, (state, substitution) =>
    state.data
      ? {
          ...state,
          data: substitution,
        }
      : state,
  );

  return { storage, putProfileSubstitutionEffect };
};
