import {
  UiFlex,
  UiSpinner,
  UiModal,
  UiModalContext,
  UiAvatar,
  UiAvatarProps,
  UiAvatarSize,
  UiButton,
  UiForm,
  UiIcon,
  UiInputNumber,
  message,
  UiSelect,
  UiOptionData,
} from '@vkph/ui';
import { useStore, useStoreMap } from 'effector-react';
import React, { FC, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useAbstractStorage } from '@vkph/common/hooks';
import { UserIdModel, GamificationBadgeId } from '@vkph/common/types/models';
import { requiredRuleSelect, getErrorResponseMessage, generateSelectDataOptions } from '@vkph/common/utils';
import { useSpace } from '@vkph/ui/hooks';
import Appreaction3Svg from '@vkph/ui/svg/appreaction-3.svg';
import { getModalStepsForSingleTitle } from '@vkph/ui/utils';
import { GamificationUserBadgesStorage, getGamificationBadgesStorage } from '~profile/store/gamification';

type Props = {
  userBadgesStorage: GamificationUserBadgesStorage;
};

interface SelectAvatarOptionProps extends Pick<UiAvatarProps, 'src'> {
  label: UiOptionData['label'];
}

type AddBadgeFormValues = {
  badgeId: GamificationBadgeId;
  coins: number;
};

export const AddBadge: FC<Props> = (props) => {
  const { onClose } = useContext(UiModalContext);
  const { userBadgesStorage } = props;
  const { spaceS } = useSpace();
  const { id: userId = '' } = useParams<{ id: UserIdModel }>();
  const [form] = UiForm.useForm<AddBadgeFormValues>();

  const sendBadgeEffectPending = useStore(userBadgesStorage.sendBadgeEffect.pending);
  const gamificationBadgesStorage = useMemo(getGamificationBadgesStorage, []);
  const { loading: badgesDataLoading, error: badgesDataError } = useAbstractStorage(
    gamificationBadgesStorage.storage,
    {
      autoFetchAndRefetch: true,
    },
  );

  const badgesOptions = useStoreMap(gamificationBadgesStorage.storage.store, ({ data }) =>
    generateSelectDataOptions(data, { valuePath: 'id', labelPath: 'name' }),
  );

  useEffect(() => {
    const isBadgesDataEmpty = !badgesOptions.length && !badgesDataLoading;

    if (badgesDataError || isBadgesDataEmpty) {
      onClose();
      message.error('Не удалось загрузить список наград. Попробуйте позже.');
    }
  }, [badgesDataError, badgesOptions, badgesDataLoading]);

  const onFinish = ({ badgeId, coins }: AddBadgeFormValues) => {
    userBadgesStorage
      .sendBadgeEffect({ user: userId, badge: badgeId, coins })
      .then(() => {
        message.success('Награда отправлена');
        onClose();
      })
      .catch((e) => {
        message.error(getErrorResponseMessage(e, 'Ошибка награждения'));
      });
  };

  const SelectAvatarOption: FC<SelectAvatarOptionProps> = (optionProps) => {
    const { src, label } = optionProps;

    return (
      <UiFlex gap={spaceS} align="center">
        <UiAvatar
          src={src}
          size={UiAvatarSize.XS}
          icon={<UiIcon component={Appreaction3Svg} width={20} height={20} />}
        />
        {label}
      </UiFlex>
    );
  };

  return (
    <UiForm form={form} requiredMark size="large" layout="vertical" onFinish={onFinish}>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle('Награждение')} />
      </UiModal.Header>

      <UiModal.Content basePadding>
        <UiForm.Item name="badgeId" label="Название награды" required rules={[requiredRuleSelect]}>
          <UiSelect
            placeholder={<SelectAvatarOption label="Выбрать" />}
            suffixIcon={badgesDataLoading ? <UiSpinner size="default" spinning /> : undefined}
            disabled={!badgesOptions.length || sendBadgeEffectPending}
          >
            {badgesOptions.map((option) => (
              <UiSelect.Option key={option.value} value={option.value}>
                <SelectAvatarOption src={option.data.image} label={option.label} />
              </UiSelect.Option>
            ))}
          </UiSelect>
        </UiForm.Item>

        <UiForm.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <UiForm.Item name="coins" label="Присвоить коины" initialValue={0}>
              <UiInputNumber
                style={{ width: 130 }}
                disabled={!getFieldValue('badgeId') || sendBadgeEffectPending}
              />
            </UiForm.Item>
          )}
        </UiForm.Item>
      </UiModal.Content>

      <UiModal.Footer hasTopBorder>
        <UiModal.Footer.Buttons>
          <UiForm.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <UiButton
                size="large"
                type="primary"
                label="Наградить"
                onClick={form.submit}
                disabled={!getFieldValue('badgeId') || sendBadgeEffectPending}
                loading={sendBadgeEffectPending}
              />
            )}
          </UiForm.Item>
          <UiButton size="large" type="tertiary" label="Отмена" onClick={onClose} />
        </UiModal.Footer.Buttons>
      </UiModal.Footer>
    </UiForm>
  );
};
